<template>
    <article>
        <hero-image
            :alt="hero.alt"
            :heading="hero.heading"
            :img-small="hero.imgSmall"
            :img-large="hero.imgLarge"
        />
        <v-container class="py-0">
            <v-row no-gutters align="center">
                <v-col :order="$vuetify.breakpoint.xsOnly ? 'last' : null" cols="12" sm="7">
                    <breadcrumbs :crumbs="crumbs" />
                </v-col>
                <v-col cols="12" sm="5">
                    <social :meta="meta" />
                </v-col>
            </v-row>
        </v-container>
        <v-container class="content" tag="section">
            <v-row>
                <v-col cols="12">
                    <p class="blockquote">
                        Sydney Kids Neuropsychology services the Greater Sydney area including; the Blue Mountains, Canterbury-Bankstown, Eastern Suburbs and CBD, Forest District, Hills District, Inner West, Lower and Upper North Shore, Macarthur, Northern Beaches, Northern Suburbs, Parramatta, Penrith, South Western Sydney, St George, Sutherland and Western Sydney. Sydney Kids Neuropsychology also services Wollongong and the South Coast.
                    </p>
                    <p>Alicia has consulting rooms based in Sutherland Shire and Stephanie has consulting rooms based in the Eastern Suburbs. Assessments can also be conducted within your child's school or at your own home.</p>
                    <p>To make an appointment with Sydney Kids Neuropsychologyor enquire about any of our services, please complete the contact form.</p>
                </v-col>
            </v-row>
        </v-container>
        <parallax-image :alt="parallax.alt" :height="parallax.height">
            <contact-form />
        </parallax-image>
    </article>
</template>

<script>
import { _MxAppViewClass, _MxMeta } from '@/mixins/global';
import Breadcrumbs from '@/components/breadcrumbs/Breadcrumbs';
import ContactForm from '@/components/form/ContactForm';
import HeroImage from '@/components/image/HeroImage';
import ParallaxImage from '@/components/image/ParallaxImage';
import Social from '@/components/social/Social';
export default {
    name: 'Contact',
    components: {
        Breadcrumbs,
        ContactForm,
        HeroImage,
        ParallaxImage,
        Social
    },
    mixins: [_MxAppViewClass, _MxMeta],
    data() {
        return {
            crumbs: [
                {
                    text: 'home',
                    disabled: false,
                    to: '/'
                },
                {
                    text: this.$route.name,
                    disabled: true
                }
            ],
            formVisible: false,
            hero: {
                alt: this.$route.name,
                heading: this.$route.name,
                imgSmall: 'hero-09-small',
                imgLarge: 'hero-09-large'
            },
            meta: {
                description: 'Contact Sydney Kids Neuropsychology.',
                title: 'Contact Us'
            },
            parallax: {
                alt: this.$route.name,
                height: null
            }
        };
    },
    created() {
        this.calcParallaxHeight();
    },
    methods: {
        calcParallaxHeight() {
            if (this.$vuetify.breakpoint.xsOnly) {
                this.parallax.height = 900;
            }
            if (this.$vuetify.breakpoint.smOnly) {
                this.parallax.height = 524;
            }
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.parallax.height = 556;
            }
        }
    }
};
</script>
